import Resizer from 'react-image-file-resizer';
import { app, analytics } from './firebase.utils';

import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable,
  deleteObject,
  getBlob,
} from 'firebase/storage';

const storage = getStorage(app);

const EVENTS_MEDIA_FOLDER = '/events/';
const USERS_MEDIA_FOLDER = '/users/';
const USERS_PROFILE_IMG_MEDIA_FOLDER = USERS_MEDIA_FOLDER + 'profile_img/';

const GROUPS_MEDIA_FOLDER = '/groups/';
const GROUPS_PROFILE_IMG_MEDIA_FOLDER = GROUPS_MEDIA_FOLDER + 'profile_img/';

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      700, // maxWidth
      2000, // maxHeight
      'JPEG', // compressFormat
      100, // quality
      0, // rotation
      (uri) => {
        resolve(uri);
      },
      'file'
    );
  });

export const getDownloadURLForStorageBucketImage = async (posted_path) => {
  if (!posted_path) return '';

  var storageRef = ref(storage, posted_path);
  const url = await getDownloadURL(storageRef);

  return url;
};

/////////////////////////////// EVENTS ///////////////////////////////

export const getDownloadURLForEventImage = async (event_object) => {
  const relative_path = event_object.EVENT_MEDIA_PATH;

  if (!relative_path) return '';
  var storageRef = ref(storage, relative_path);
  const url = await getDownloadURL(storageRef);

  return url;
};

export const uploadGroupImageFileToEventImageStorage = async (
  filename,
  image_storage_path,
  created_at
) => {
  var path_with_extension = EVENTS_MEDIA_FOLDER + filename + '.jpeg';
  var storageRef = ref(storage, path_with_extension);

  var groupStorageRef = ref(storage, image_storage_path);
  const blob_file = await getBlob(groupStorageRef);

  const metadata = {
    cacheControl: 'public,max-age=86400',
    customMetadata: {
      EVENT_ID: filename,
      created: created_at,
    },
  };

  await uploadBytesResumable(storageRef, blob_file, metadata);

  return path_with_extension;
};

export const uploadImageFileArrayToEventImageStorage = async (
  file_array,
  created_at
) => {
  try {
    for (const key of Object.keys(file_array)) {
      var event_id = key;
      var image_file = file_array[key];

      const resizedImage = await resizeFile(image_file);

      var path_with_extension = EVENTS_MEDIA_FOLDER + event_id + '.jpeg';
      var storageRef = ref(storage, path_with_extension);

      const metadata = {
        cacheControl: 'public,max-age=86400',
        customMetadata: {
          EVENT_ID: event_id,
          created: created_at,
        },
      };

      await uploadBytesResumable(storageRef, resizedImage, metadata);
    }
    return { success: true };
  } catch (error) {
    console.error(error);
    return { success: false };
  }
};

export const deleteEventMediaFromStorage = async (EVENT_MEDIA_PATH) => {
  var storageRef = ref(storage, EVENT_MEDIA_PATH);

  await deleteObject(storageRef).catch((error) => {
    console.error('Error deleting media from storage: ', error);
  });
};
