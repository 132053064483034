import { auth } from './firebase.utils';

import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  sendEmailVerification,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';

import { getUserDisabledField } from './db-firebase.utils';
import { logUserAuthActionAnalytics } from './analytics-firebase.utils';

import CONSTANTS from '../../infrastructure/config';

const convertErrorToReturnCode = (error) => {
  switch (error.code) {
    case 'auth/invalid-email':
      return {
        return_code: CONSTANTS.AUTH.RETURN_CODES.ERROR,
        specific_code: CONSTANTS.AUTH.RETURN_CODES.INVALID_EMAIL,
        return_info: `Invalid email address (Error ${CONSTANTS.AUTH.RETURN_CODES.INVALID_EMAIL})`,
      };
    case 'auth/invalid-password':
      return {
        return_code: CONSTANTS.AUTH.RETURN_CODES.ERROR,
        specific_code: CONSTANTS.AUTH.RETURN_CODES.INVALID_PASSWORD,
        return_info: `Invalid password. Length must be at least 6 characters. (Error ${CONSTANTS.AUTH.RETURN_CODES.INVALID_PASSWORD})`,
      };
    case 'auth/user-disabled':
      return {
        return_code: CONSTANTS.AUTH.RETURN_CODES.ERROR,
        specific_code: CONSTANTS.AUTH.RETURN_CODES.DISABLED_ACCOUNT,
        return_info: `Account disabled (Error ${CONSTANTS.AUTH.RETURN_CODES.DISABLED_ACCOUNT})`,
      };
    case 'auth/wrong-password':
      return {
        return_code: CONSTANTS.AUTH.RETURN_CODES.ERROR,
        specific_code: CONSTANTS.AUTH.RETURN_CODES.INCORRECT_PASSWORD,
        return_info: `Incorrect password (Error ${CONSTANTS.AUTH.RETURN_CODES.INCORRECT_PASSWORD})`,
      };
    case 'auth/email-already-in-use': // register
      return {
        return_code: CONSTANTS.AUTH.RETURN_CODES.ERROR,
        specific_code: CONSTANTS.AUTH.RETURN_CODES.EMAIL_ALREADY_IN_USE,
        return_info: `Email already in use (Error ${CONSTANTS.AUTH.RETURN_CODES.EMAIL_ALREADY_IN_USE})`,
      };
    case 'auth/phone-number-already-in-use': // register
      return {
        return_code: CONSTANTS.AUTH.RETURN_CODES.ERROR,
        specific_code: CONSTANTS.AUTH.RETURN_CODES.PHONE_NUMBER_ALREADY_IN_USE,
        return_info: `Phone number already in use (Error ${CONSTANTS.AUTH.RETURN_CODES.PHONE_NUMBER_ALREADY_IN_USE})`,
      };
    case 'auth/too-many-requests':
      return {
        return_code: CONSTANTS.AUTH.RETURN_CODES.ERROR,
        specific_code: CONSTANTS.AUTH.RETURN_CODES.TOO_MANY_REQUESTS,
        return_info: `Too many requests (Error ${CONSTANTS.AUTH.RETURN_CODES.TOO_MANY_REQUESTS})`,
      };
    case 'auth/user-not-found':
      return {
        return_code: CONSTANTS.AUTH.RETURN_CODES.ERROR,
        specific_code: CONSTANTS.AUTH.RETURN_CODES.USER_NOT_FOUND,
        return_info: `User not found (Error ${CONSTANTS.AUTH.RETURN_CODES.USER_NOT_FOUND})`,
      };
    default:
      return {
        return_code: CONSTANTS.AUTH.RETURN_CODES.ERROR,
        specific_code: null,
        return_info: `Unknown error. Please contact support. (Error ${CONSTANTS.AUTH.RETURN_CODES.ERROR})`,
      };
  }
};

//////////////

export const logInWithEmailAndPassword = async (email, password) => {
  var auth_user = null;

  try {
    await signInWithEmailAndPassword(auth, email, password).then(
      (user) => (auth_user = user)
    );

    if (auth_user && auth_user?.user?.emailVerified === false) {
      return {
        return_code: CONSTANTS.AUTH.RETURN_CODES.UNVERIFIED_EMAIL,
        return_info: auth_user,
      };
    }

    const disabled_flag = await getUserDisabledField(auth_user?.user?.email);

    if (disabled_flag) {
      return {
        return_code: CONSTANTS.AUTH.RETURN_CODES.DISABLED_ACCOUNT,
        return_info: null,
      };
    }

    logUserAuthActionAnalytics('login_success', auth_user?.user?.email);
    return {
      return_code: CONSTANTS.AUTH.RETURN_CODES.SUCCESS,
      return_info: null,
    };
  } catch (error) {
    return convertErrorToReturnCode(error);
  }
};

export const sendVerificationEmail = async (firebaseUser) => {
  try {
    const res = await sendEmailVerification(firebaseUser.user);

    return CONSTANTS.AUTH.RETURN_CODES.SUCCESS;
  } catch (error) {
    return convertErrorToReturnCode(error);
  }
};

export const registerWithEmailAndPassword = async (email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    const uid = user.uid;
    const authProvider = 'local';

    await logUserAuthActionAnalytics('register_success', email);

    await sendVerificationEmail(res);

    return {
      return_code: CONSTANTS.AUTH.RETURN_CODES.SUCCESS,
      return_info: null,
    };
  } catch (error) {
    return convertErrorToReturnCode(error);
  }
};

export const sendPasswordReset = async (email) => {
  var email_domain = email.split('@')[1];

  // if (ALLOWED_DOMAINS.indexOf(email_domain) === -1) {
  //   //'unapproved redirect'
  //   return 201;
  // }

  return await sendPasswordResetEmail(auth, email);
};

export const logout = async () => {
  await signOut(auth);

  //logUserAuthActionAnalytics('logout_success', auth.user.email);
};

export const onAuthStateChangedListener = (callback) =>
  onAuthStateChanged(auth, callback);
