import { logEvent } from 'firebase/analytics';

import { analytics } from './firebase.utils';

export const logWebsiteFormSubmissionAnalytics = async (
  type,
  form_data = {}
) => {
  await logEvent(analytics, 'website_form_submission', {
    type,
    form_data: { ...form_data },
  });
};

export const logPageVisitAnalytics = async (page_name) => {
  await logEvent(analytics, 'website_page_visit', { page: page_name });
};

export const logUserAuthActionAnalytics = async (type, user_email) => {
  await logEvent(analytics, 'user_auth_action', { type, user_email });
};
