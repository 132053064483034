import { doc, setDoc, collection } from 'firebase/firestore';

import { db } from './db-firebase.utils';

import CONSTANTS from '../../infrastructure/config';

const MAIL_QUEUE_COLLECTION = 'sendgrid-email-queue';
const TEMPLATES = {
  INVITE_NOT_REGISTERED_USER_TO_GROUP:
    'group_invite_not_registered_email_template',
};

export const groupInviteNotRegisteredUserEmailDataForCSVBatch = async (
  toEmail,
  role,
  groupAdminName,
  groupObject,
  mapObject
) => {
  const APP_NAME = CONSTANTS.METADATA.APP_NAME;
  const WEBSITE_URL = CONSTANTS.URLS.BASE_URL;
  const APP_DOWNLOAD_URL = CONSTANTS.URLS.APP_DOWNLOAD_URL;

  const GROUP_ADMIN_NAME = groupAdminName;
  const GROUP_SHORT_NAME = groupObject.GROUP_NAME_SHORT;
  const MAP_NAME = mapObject.UNIV_NAME;
  const ROLE = role;
  const EMAIL = toEmail;

  const today = new Date();
  const week_from_today = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);

  return {
    type: 'set',
    ref: doc(collection(db, MAIL_QUEUE_COLLECTION)),
    data: {
      TTL: week_from_today,
      to: toEmail,
      template: {
        name: TEMPLATES.INVITE_NOT_REGISTERED_USER_TO_GROUP,
        data: {
          APP_NAME,
          WEBSITE_URL,
          APP_DOWNLOAD_URL,
          GROUP_ADMIN_NAME,
          GROUP_SHORT_NAME,
          MAP_NAME,
          ROLE,
          EMAIL,
        },
      },
    },
  };
};

export const sendGroupInviteNotRegisteredUserEmail = async ({
  toEmail,
  role,
  groupAdminName,
  groupObject,
  mapObject,
}) => {
  const APP_NAME = CONSTANTS.METADATA.APP_NAME;
  const WEBSITE_URL = CONSTANTS.URLS.BASE_URL;
  const APP_DOWNLOAD_URL = CONSTANTS.URLS.APP_DOWNLOAD_URL;

  const GROUP_ADMIN_NAME = groupAdminName;
  const GROUP_SHORT_NAME = groupObject.GROUP_NAME_SHORT;
  const MAP_NAME = mapObject.UNIV_NAME;
  const ROLE = role;
  const EMAIL = toEmail;

  const today = new Date();
  const week_from_today = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);

  await setDoc(doc(collection(db, MAIL_QUEUE_COLLECTION)), {
    TTL: week_from_today,
    to: toEmail,
    template: {
      name: TEMPLATES.INVITE_NOT_REGISTERED_USER_TO_GROUP,
      data: {
        APP_NAME,
        WEBSITE_URL,
        APP_DOWNLOAD_URL,
        GROUP_ADMIN_NAME,
        GROUP_SHORT_NAME,
        MAP_NAME,
        ROLE,
        EMAIL,
      },
    },
  });
};
