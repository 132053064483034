import { app } from './firebase.utils';

import {
  getFirestore,
  query,
  getDocs,
  getDoc,
  collection,
  where,
  doc,
  setDoc,
  arrayRemove,
  arrayUnion,
  updateDoc,
  increment,
  GeoPoint,
  orderBy,
  startAfter,
  limit,
  startAt,
  endAt,
  deleteDoc,
  or,
  getCountFromServer,
  writeBatch,
  deleteField,
} from 'firebase/firestore';

import {
  uploadGroupImageFileToEventImageStorage,
  deleteEventMediaFromStorage,
} from './storage-firebase.utils';

import { logWebsiteFormSubmissionAnalytics } from './analytics-firebase.utils';

import {
  sendGroupInviteNotRegisteredUserEmail,
  groupInviteNotRegisteredUserEmailDataForCSVBatch,
} from './mail-firebase.utils';

import CONSTANTS from '../../infrastructure/config';

const geofire = require('geofire-common');

export const db = getFirestore(app);

const USERS_COLLECTION = 'users';
const MAPS_COLLECTION = 'maps';
const GROUPS_COLLECTION = 'groups';
const EVENTS_COLLECTION = 'events';
const MARKETING_GROUP_INVITES_COLLECTION = 'marketing-group-invites';
const PUSH_NOTIFICATIONS_QUEUE_COLLECTION = 'push-notification-queue';
const DOWNLOAD_REFERRAL_COLLECTION = 'download-referral';

const FIREBASE_MAX_BATCH_SIZE = 450;

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const CHARACTER_LIMITS = {
  EVENT_TITLE: 75,
  EVENT_DESCRIPTION: 1000,
  EVENT_ONLINE_LINK: 500,
  EVENT_LOCATION_DETAILS: 300,
};

Object.defineProperty(String.prototype, 'capitalize', {
  value: function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false,
});

const extractSnapshots = (snapshots) => {
  let extracts = [];
  snapshots.forEach((documentSnapshot) => {
    extracts.push(documentSnapshot.data());
  });
  return extracts;
};

const extractMembersSnapshots = (snapshots) => {
  let extracts = [];
  snapshots.forEach((documentSnapshot) => {
    const data = documentSnapshot.data();
    data.DATETIME_JOINED_FORMATTED =
      data.DATETIME_JOINED.toDate().toLocaleString();
    extracts.push(data);
  });
  return extracts;
};

const extractEventsSnapshots = (snapshots) => {
  let extracts = [];
  snapshots.forEach((documentSnapshot) => {
    const data = documentSnapshot.data();

    data.EVENT_DATETIME_FORMATTED =
      data.EVENT_DATETIME.toDate().toLocaleString();
    data.EVENT_DURATION_FORMATTED =
      data.EVENT_DURATION.toDate().toLocaleString();
    data.EVENT_POSTED_DATE_FORMATTED =
      data.EVENT_POSTED_DATE.toDate().toLocaleString();

    data.EVENT_FORMAT_FORMATTED = data.EVENT_FORMAT.capitalize();
    data.EVENT_VISIBILITY_FORMATTED = data.EVENT_VISIBILITY.capitalize();

    data.EVENT_CAPACITY_FORMATTED =
      data.EVENT_CAPACITY === 0 ? 'Any Size' : data.EVENT_CAPACITY;
    data.EVENT_APPROVED_FORMATTED = data.EVENT_APPROVED === true ? 'Yes' : 'No';

    data.EVENT_FOOD_SERVED_FORMATTED =
      data.EVENT_FOOD_SERVED === true ? 'Yes' : 'No';
    data.EVENT_TICKETED_FORMATTED = data.EVENT_TICKETED === true ? 'Yes' : 'No';
    data.EVENT_ALL_DAY_FORMATTED = data.EVENT_ALL_DAY === true ? 'Yes' : 'No';

    data.GEO_LOC_FORMATTED = `${data.GEO_LOC.latitude}, ${data.GEO_LOC.longitude}`;

    extracts.push(data);
  });
  return extracts;
};

function RandomCodeGenerator(length) {
  var text = '';

  var upper_case_letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var lower_case_letters = 'abcdefghijklmnopqrstuvwxyz';
  var numbers = '0123456789';
  var possible = upper_case_letters + lower_case_letters + numbers;

  for (var i = 0; i < length; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

const executeBatchActions = async (batchActions) => {
  if (batchActions.length > 0) {
    if (batchActions.length > FIREBASE_MAX_BATCH_SIZE) {
      // split into multiple batches
      var batchActionsSplit = [];

      while (batchActions.length > 0) {
        await batchActionsSplit.push(
          batchActions.splice(0, FIREBASE_MAX_BATCH_SIZE)
        );
      }

      for (let batchGroup of batchActionsSplit) {
        const batch = writeBatch(db);

        for (let action of batchGroup) {
          if (action.type === 'set') batch.set(action.ref, action.data);
          else if (action.type === 'update')
            batch.update(action.ref, action.data);
          else if (action.type === 'delete') batch.delete(action.ref);
        }

        await batch.commit();
      }
    } else {
      // single batch
      const batch = writeBatch(db);

      for (let action of batchActions) {
        if (action.type === 'set') batch.set(action.ref, action.data);
        else if (action.type === 'update')
          batch.update(action.ref, action.data);
        else if (action.type === 'delete') batch.delete(action.ref);
      }

      await batch.commit();
    }
  } else return;
};

// temp

export const getAllUserAccountsForEmail = async (UNIT_ID) => {
  try {
    const q = query(
      collection(db, USERS_COLLECTION),
      where('MAP_ACCESS', 'array-contains', UNIT_ID)
    );

    var user_info = [];

    const snapshot = await getDocs(q);

    await snapshot.forEach((doc) => {
      var data = doc.data();

      user_info.push({
        FIRST_NAME: data.FIRST_NAME || '',
        LAST_NAME: data.LAST_NAME || '',
        EMAIL_ADDRESS: data.EMAIL_ADDRESS || '',
      });
    });

    return user_info;
  } catch (error) {
    console.error(error);
  }
};

export const sendPushToAllUsers = async (UNIT_ID) => {
  try {
    const q = query(
      collection(db, USERS_COLLECTION),
      where('MAP_ACCESS', 'array-contains', UNIT_ID)
    );

    var all_user_ids = [];

    const snapshot = await getDocs(q);

    await snapshot.forEach((doc) => {
      all_user_ids.push(doc.data().USER_ID);
    });

    // await setDoc(doc(collection(db, PUSH_NOTIFICATIONS_QUEUE_COLLECTION)), {
    //   TO: all_user_ids,
    //   TITLE: 'Mixer',
    //   BODY: `Enjoying Mixer? Share Mixer with your friends at Stanford!`,
    //   TTL: 86400,
    //   DATA: {},
    //   PRIORITY: 'default',
    //   SOUND: 'default',
    //   ADDED_TO_QUEUE: new Date(),
    // });

    await setDoc(doc(collection(db, PUSH_NOTIFICATIONS_QUEUE_COLLECTION)), {
      TO: all_user_ids,
      TITLE: 'Mixer',
      BODY: `27 new events just dropped on Mixer! Check them out...`,
      TTL: 86400,
      DATA: {},
      PRIORITY: 'default',
      SOUND: 'default',
      ADDED_TO_QUEUE: new Date(),
    });
  } catch (error) {
    console.error(error);
  }
};

export const addFieldsTEMP = async () => {
  try {
    var batchActions = [];
    // get all docs from a collection
    const users_ref = query(collection(db, USERS_COLLECTION));
    const usersQuerySnapshot = await getDocs(users_ref);
    usersQuerySnapshot.forEach(async (docData) => {
      const doc_id = docData.id;
      const doc_ref = doc(db, USERS_COLLECTION, doc_id);

      if (!docData.data().ALL_CHECKED_IN_EVENTS) {
        console.log(
          'adding ALL_CHECKED_IN_EVENTS to user doc with id: ',
          doc_id
        );

        // batchActions.push({
        //   type: 'update',
        //   ref: doc_ref,
        //   data: {
        //     ALL_CHECKED_IN_EVENTS: [],
        //   },
        // });
      }

      if (!docData.data().ALL_INVITED_EVENTS) {
        console.log('adding ALL_INVITED_EVENTS to user doc with id: ', doc_id);

        // batchActions.push({
        //   type: 'update',
        //   ref: doc_ref,
        //   data: {
        //     ALL_INVITED_EVENTS: [],
        //   },
        // });
      }

      if (!docData.data().INVITED_EVENTS_RESPONDED) {
        console.log(
          'adding INVITED_EVENTS_RESPONDED to user doc with id: ',
          doc_id
        );

        // batchActions.push({
        //   type: 'update',
        //   ref: doc_ref,
        //   data: {
        //     INVITED_EVENTS_RESPONDED: [],
        //   },
        // });
      }

      if (!docData.data().INVITED_EVENTS_PENDING_RESPONSE) {
        console.log(
          'adding INVITED_EVENTS_PENDING_RESPONSE to user doc with id: ',
          doc_id
        );

        // batchActions.push({
        //   type: 'update',
        //   ref: doc_ref,
        //   data: {
        //     INVITED_EVENTS_PENDING_RESPONSE: [],
        //   },
        // });
      }

      if (!'USER_DISABLED_REASON' in docData.data()) {
        console.log(
          'adding USER_DISABLED_REASON to user doc with id: ',
          doc_id
        );

        // batchActions.push({
        //   type: 'update',
        //   ref: doc_ref,
        //   data: {
        //     USER_DISABLED_REASON: "",
        //   },
        // });
      }
    });

    const events_ref = query(collection(db, EVENTS_COLLECTION));
    const eventsQuerySnapshot = await getDocs(events_ref);
    eventsQuerySnapshot.forEach(async (docData) => {
      const doc_id = docData.id;
      const doc_ref = doc(db, EVENTS_COLLECTION, doc_id);

      if (!docData.data().USER_ID_CHECKED_IN) {
        console.log('adding USER_ID_CHECKED_IN to event doc with id: ', doc_id);

        // batchActions.push({
        //   type: 'update',
        //   ref: doc_ref,
        //   data: {
        //     USER_ID_CHECKED_IN: [],
        //   },
        // });
      }

      if (!docData.data().USER_ID_INVITED_ALL) {
        console.log(
          'adding USER_ID_INVITED_ALL to event doc with id: ',
          doc_id
        );

        // batchActions.push({
        //   type: 'update',
        //   ref: doc_ref,
        //   data: {
        //     USER_ID_INVITED_ALL: [],
        //   },
        // });
      }

      if (!docData.data().USER_ID_INVITED_RESPONDED) {
        console.log(
          'adding USER_ID_INVITED_RESPONDED to event doc with id: ',
          doc_id
        );

        // batchActions.push({
        //   type: 'update',
        //   ref: doc_ref,
        //   data: {
        //     USER_ID_INVITED_RESPONDED: [],
        //   },
        // });
      }

      if (!docData.data().USER_ID_INVITED_PENDING_RESPONSE) {
        console.log(
          'adding USER_ID_INVITED_PENDING_RESPONSE to event doc with id: ',
          doc_id
        );

        // batchActions.push({
        //   type: 'update',
        //   ref: doc_ref,
        //   data: {
        //     USER_ID_INVITED_PENDING_RESPONSE: [],
        //   },
        // });
      }

      if (!docData.data().USER_ID_INVITATION_OWNERSHIP) {
        console.log(
          'adding USER_ID_INVITATION_OWNERSHIP to event doc with id: ',
          doc_id
        );

        // batchActions.push({
        //   type: 'update',
        //   ref: doc_ref,
        //   data: {
        //     USER_ID_INVITATION_OWNERSHIP: [],
        //   },
        // });
      }

      if (!'EVENT_DISABLED' in docData.data()) {
        console.log('adding EVENT_DISABLED to event doc with id: ', doc_id);

        // batchActions.push({
        //   type: 'update',
        //   ref: doc_ref,
        //   data: {
        //     EVENT_DISABLED: false,
        //   },
        // });
      }

      if (!'EVENT_DISABLED_BY' in docData.data()) {
        console.log('adding EVENT_DISABLED_BY to event doc with id: ', doc_id);

        // batchActions.push({
        //   type: 'update',
        //   ref: doc_ref,
        //   data: {
        //     EVENT_DISABLED_BY: "",
        //   },
        // });
      }

      if (!'EVENT_DISABLED_DATETIME' in docData.data()) {
        console.log(
          'adding EVENT_DISABLED_DATETIME to event doc with id: ',
          doc_id
        );

        // batchActions.push({
        //   type: 'update',
        //   ref: doc_ref,
        //   data: {
        //     EVENT_DISABLED_DATETIME: null,
        //   },
        // });
      }

      if (!'EVENT_DISABLED_REASON' in docData.data()) {
        console.log(
          'adding EVENT_DISABLED_REASON to event doc with id: ',
          doc_id
        );

        // batchActions.push({
        //   type: 'update',
        //   ref: doc_ref,
        //   data: {
        //     EVENT_DISABLED_REASON: "",
        //   },
        // });
      }

      if (!'EVENT_VERIFIED' in docData.data()) {
        console.log('adding EVENT_VERIFIED to event doc with id: ', doc_id);

        // batchActions.push({
        //   type: 'update',
        //   ref: doc_ref,
        //   data: {
        //     EVENT_VERIFIED: true,
        //   },
        // });
      }
    });

    await executeBatchActions(batchActions);
  } catch (error) {
    console.error(error);
  }
};

//////////////////////////// WAITLIST COLLECTION //////////////////////////

export const addToWaitlist = async (email, state, city, college) => {
  await setDoc(doc(db, 'waitlist', email), {
    email,
    state,
    city,
    university: college,
  });

  await logWebsiteFormSubmissionAnalytics('waitlist', {
    email,
    state,
    city,
    college,
  });
};

//////////////////////////// REFERRAL COLLECTION //////////////////////////

export const addReferral = async (referral_code) => {
  const docRef = doc(db, DOWNLOAD_REFERRAL_COLLECTION, referral_code);

  const docSnapshot = await getDoc(docRef);
  if (!docSnapshot.exists()) {
    // If doc doesn't exist, set it with count = 1
    return await setDoc(docRef, { count: [new Date()] });
  } else {
    // If doc exists, increment count
    return await updateDoc(docRef, { count: arrayUnion(new Date()) });
  }
};

//////////////////////////// USERS COLLECTION //////////////////////////

export const getUserMetadata = async (user) => {
  if (user === null) return null;
  try {
    const q = query(
      collection(db, USERS_COLLECTION),
      where('EMAIL_ADDRESS', '==', user.email)
    );
    const doc = await getDocs(q);

    if (doc.docs.length === 0) return null;

    const data = doc.docs[0].data();

    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getUserDisabledField = async (email) => {
  try {
    const q = query(
      collection(db, USERS_COLLECTION),
      where('EMAIL_ADDRESS', '==', email)
    );
    const doc = await getDocs(q);
    const data = doc.docs[0].data();
    return data.USER_DISABLED;
  } catch (error) {
    console.error(error);
  }
};

export const createNewUserDoc = async ({
  firstName,
  lastName,
  email,
  phoneNumber,
}) => {
  const createdAt = new Date();

  const USER_ID = await RandomCodeGenerator(16);

  await setDoc(doc(db, USERS_COLLECTION, USER_ID), {
    USER_ID,
    FIRST_NAME: firstName,
    MIDDLE_NAME: '',
    LAST_NAME: lastName,
    PHONE_NUMBER: phoneNumber,
    EMAIL_ADDRESS: email,
    USER_ROLE: 'user',
    MAP_ACCESS: ['u243744'], // Stanford University
    CURRENT_SUB_TIER: 1,
    //PROFILE_IMG_PATH: `/users/profile_img/${USER_ID}.jpeg`,
    PROFILE_IMG_PATH: '',
    BIO: '',
    ALL_INVITED_EVENTS: [],
    ALL_CHECKED_IN_EVENTS: [],
    INVITED_EVENTS_RESPONDED: [],
    INVITED_EVENTS_PENDING_RESPONSE: [],
    ATTENDED_EVENTS: [],
    GOING_EVENTS: [],
    INTERESTED_EVENTS: [],
    POSTED_EVENTS: [],
    ADMIN_GROUPS: [],
    MEMBER_GROUPS: [],
    DATETIME_JOINED: createdAt,
    UNIVERSITY_AFFILIATED: true,
    CURRENT_GRADE: '',
    USER_DISABLED: false,
    USER_DISABLED_BY: '',
    USER_DISABLED_REASON: '',
    USER_DISABLED_DATETIME: null,
    EMAIL_VERIFIED: true,
    PHONE_NUMBER_VERIFIED: true,
    USER_LINKS: [],
    USER_SETTINGS: {
      SHOW_GROUPS_ON_PUBLIC_PROFILE: true,
      SHOW_EVENTS_ON_PUBLIC_PROFILE: true,
    },
    EXPO_DEVICE_PUSH_TOKENS: [],
  });

  return USER_ID;
};

//////////////////////////// MAPS COLLECTION //////////////////////////

// export const getAllMapObjectsForDropdown = async () => {
//   try {
//       const collection_ref = doc(db, MAPS_COLLECTION);

//       const docSnap = await getDoc(ref);

//       if (!docSnap.data().MAP_DISABLED) {
//         return_array_data.push({
//           value: docSnap.data(),
//           label: docSnap.data().UNIV_NAME,
//         });
//       }
//     }

//     return return_array_data;
//   } catch (error) {
//     console.error(error);
//   }
// };

export const getCommunityMapObjectFromID = async (unit_id) => {
  try {
    const docSnap = await getDoc(doc(db, MAPS_COLLECTION, unit_id));

    return docSnap.data();
  } catch (error) {
    console.error(error);
  }
};

//////////////////////////// GROUPS COLLECTION //////////////////////////

export const getGroupMembersCount = async (GROUP_ID) => {
  try {
    const q = query(
      collection(db, USERS_COLLECTION),
      where('MEMBER_GROUPS', 'array-contains', GROUP_ID),
      where('USER_ROLE', '==', 'user'),
      where('USER_DISABLED', '==', false)
    );

    const snapshot = await getCountFromServer(q);

    return snapshot.data().count;
  } catch (error) {
    console.error(error);
  }
};

export const getGroupAdminsCount = async (GROUP_ID) => {
  try {
    const q = query(
      collection(db, USERS_COLLECTION),
      where('ADMIN_GROUPS', 'array-contains', GROUP_ID),
      where('USER_ROLE', '==', 'user'),
      where('USER_DISABLED', '==', false)
    );

    const snapshot = await getCountFromServer(q);

    return snapshot.data().count;
  } catch (error) {
    console.error(error);
  }
};

export const getGroupObjectsFromGroupArrayForDropdown = async (group_ids) => {
  try {
    var return_array_data = [];

    for (let group_id of group_ids) {
      const docSnap = await getDoc(doc(db, GROUPS_COLLECTION, group_id));

      if (!docSnap.data().GROUP_DISABLED) {
        return_array_data.push({
          // object: docSnap.data(),
          label: docSnap.data().GROUP_NAME_SHORT,
          value: docSnap.data().GROUP_ID,
          object: docSnap.data(),
          key: docSnap.data().GROUP_ID,
        });
      }
    }

    return return_array_data;
  } catch (error) {
    console.error(error);
  }
};

export const switchGroupMemberToAdmin = async (group_id, USER_ID) => {
  try {
    const group_id_ref = doc(db, GROUPS_COLLECTION, group_id);

    await updateDoc(group_id_ref, {
      ADMINS: arrayUnion(USER_ID),
      MEMBERS: arrayRemove(USER_ID),
    });

    await updateDoc(doc(db, USERS_COLLECTION, USER_ID), {
      ADMIN_GROUPS: arrayUnion(group_id),
      MEMBER_GROUPS: arrayRemove(group_id),
    });
  } catch (error) {
    console.error(error);
  }
};

export const switchGroupAdminToMember = async (group_id, USER_ID) => {
  try {
    const group_id_ref = doc(db, GROUPS_COLLECTION, group_id);

    await updateDoc(group_id_ref, {
      ADMINS: arrayRemove(USER_ID),
      MEMBERS: arrayUnion(USER_ID),
    });

    await updateDoc(doc(db, USERS_COLLECTION, USER_ID), {
      ADMIN_GROUPS: arrayRemove(group_id),
      MEMBER_GROUPS: arrayUnion(group_id),
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteGroupMember = async (group_id, USER_ID) => {
  try {
    const group_id_ref = doc(db, GROUPS_COLLECTION, group_id);

    await updateDoc(group_id_ref, {
      MEMBERS: arrayRemove(USER_ID),
      ADMINS: arrayRemove(USER_ID),
    });

    await updateDoc(doc(db, USERS_COLLECTION, USER_ID), {
      MEMBER_GROUPS: arrayRemove(group_id),
      ADMIN_GROUPS: arrayRemove(group_id),
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteGroupMemberFromUserIDArray = async (
  group_member_user_id_array,
  GROUP_ID
) => {
  try {
    const group_id_ref = doc(db, GROUPS_COLLECTION, GROUP_ID);
    var batchActions = [];

    for (let USER_ID of group_member_user_id_array) {
      const user_id_ref = doc(db, USERS_COLLECTION, USER_ID);

      batchActions.push({
        type: 'update',
        ref: group_id_ref,
        data: {
          MEMBERS: arrayRemove(USER_ID),
          ADMINS: arrayRemove(USER_ID),
        },
      });

      batchActions.push({
        type: 'update',
        ref: user_id_ref,
        data: {
          MEMBER_GROUPS: arrayRemove(GROUP_ID),
          ADMIN_GROUPS: arrayRemove(GROUP_ID),
        },
      });
    }

    await executeBatchActions(batchActions);

    return CONSTANTS.DB_RETURN_CODES.SUCCESS;
  } catch (error) {
    console.error(error);
    return CONSTANTS.DB_RETURN_CODES.FAILED;
  }
};

export const loadGroupMembersListWithPagination = async ({
  lastMemberUserId,
  GROUP_ID,
  results_limit = 10,
  direction = CONSTANTS.FETCH_DIRECTION.NEXT,
}) => {
  let docs = [];
  let newLastMemberUserId = null;
  let error = null;
  let batch;

  let status = 'UNDETERMINED';

  try {
    if (lastMemberUserId) {
      const lastDoc = await getDoc(doc(db, USERS_COLLECTION, lastMemberUserId));

      batch = query(
        collection(db, USERS_COLLECTION),
        orderBy('FIRST_NAME'),
        orderBy('LAST_NAME'),
        startAfter(lastDoc),
        limit(results_limit),
        where('MEMBER_GROUPS', 'array-contains', GROUP_ID),
        where('USER_ROLE', '==', 'user'),
        where('USER_DISABLED', '==', false)
      );
    } else {
      batch = query(
        collection(db, USERS_COLLECTION),
        orderBy('FIRST_NAME'),
        orderBy('LAST_NAME'),
        limit(results_limit),
        where('MEMBER_GROUPS', 'array-contains', GROUP_ID),
        where('USER_ROLE', '==', 'user'),
        where('USER_DISABLED', '==', false)
      );
    }

    status = 'PENDING';
    const snapshots = await getDocs(batch);

    newLastMemberUserId =
      snapshots.docs[snapshots.docs.length - 1]?.data()?.USER_ID || null;

    docs = extractMembersSnapshots(snapshots);
    status = 'SUCCESS';

    return {
      status,
      error,
      docs,
      lastMemberUserId: newLastMemberUserId,
    };
  } catch (error) {
    status = 'FAILED';
    return {
      status,
      error: error,
      docs,
      lastMemberUserId: newLastMemberUserId,
    };
  }
};

export const loadGroupAdminsListWithPagination = async ({
  lastMemberUserId,
  GROUP_ID,
  results_limit = 10,
  direction = CONSTANTS.FETCH_DIRECTION.NEXT,
}) => {
  let docs = [];
  let newLastMemberUserId = null;
  let error = null;
  let batch;

  let status = 'UNDETERMINED';

  try {
    if (lastMemberUserId) {
      const lastDoc = await getDoc(doc(db, USERS_COLLECTION, lastMemberUserId));

      batch = query(
        collection(db, USERS_COLLECTION),
        orderBy('FIRST_NAME'),
        orderBy('LAST_NAME'),
        startAfter(lastDoc),
        limit(results_limit),
        where('ADMIN_GROUPS', 'array-contains', GROUP_ID),
        where('USER_ROLE', '==', 'user'),
        where('USER_DISABLED', '==', false)
      );
    } else {
      batch = query(
        collection(db, USERS_COLLECTION),
        orderBy('FIRST_NAME'),
        orderBy('LAST_NAME'),
        limit(results_limit),
        where('ADMIN_GROUPS', 'array-contains', GROUP_ID),
        where('USER_ROLE', '==', 'user'),
        where('USER_DISABLED', '==', false)
      );
    }

    status = 'PENDING';
    const snapshots = await getDocs(batch);

    newLastMemberUserId =
      snapshots.docs[snapshots.docs.length - 1]?.data()?.USER_ID || null;

    docs = extractMembersSnapshots(snapshots);
    status = 'SUCCESS';

    return {
      status,
      error,
      docs,
      lastMemberUserId: newLastMemberUserId,
    };
  } catch (error) {
    status = 'FAILED';
    return {
      status,
      error: error,
      docs,
      lastMemberUserId: newLastMemberUserId,
    };
  }
};

export const addMemberToGroup = async (
  group_object,
  user_email,
  role,
  map_object,
  group_admin_name
) => {
  try {
    const GROUP_ID = group_object.GROUP_ID;
    const group_id_ref = doc(db, GROUPS_COLLECTION, GROUP_ID);

    const user_doc_query = query(
      collection(db, USERS_COLLECTION),
      where('EMAIL_ADDRESS', '==', user_email)
    );

    const querySnapshot = await getDocs(user_doc_query);

    if (querySnapshot.empty) {
      // console.error('User does not exist');

      await addMarketingGroupInvite(user_email, GROUP_ID, role);

      await sendGroupInviteNotRegisteredUserEmail({
        toEmail: user_email,
        role,
        groupAdminName: group_admin_name,
        groupObject: group_object,
        mapObject: map_object,
      });

      return CONSTANTS.DB_RETURN_CODES.USER.USER_DOES_NOT_EXIST;
    }

    await querySnapshot.forEach(async (queryDoc) => {
      const USER_ID = queryDoc.data().USER_ID;
      const user_doc_ref = doc(db, USERS_COLLECTION, USER_ID);

      if (role === CONSTANTS.GROUP_ROLES.ADMIN) {
        await updateDoc(group_id_ref, {
          ADMINS: arrayUnion(USER_ID),
          MEMBERS: arrayRemove(USER_ID),
        });

        await updateDoc(user_doc_ref, {
          ADMIN_GROUPS: arrayUnion(GROUP_ID),
          MEMBER_GROUPS: arrayRemove(GROUP_ID),
        });
      } else if (role === CONSTANTS.GROUP_ROLES.MEMBER) {
        await updateDoc(group_id_ref, {
          MEMBERS: arrayUnion(USER_ID),
          ADMINS: arrayRemove(USER_ID),
        });

        await updateDoc(user_doc_ref, {
          MEMBER_GROUPS: arrayUnion(GROUP_ID),
          ADMIN_GROUPS: arrayRemove(GROUP_ID),
        });
      }
    });

    return CONSTANTS.DB_RETURN_CODES.SUCCESS;
  } catch (error) {
    console.error(error);
  }
};

//////////////////////////// EVENTS COLLECTION //////////////////////////

export const getEventObjectByEventID = async (EVENT_ID) => {
  try {
    const docSnap = await getDoc(doc(db, EVENTS_COLLECTION, EVENT_ID));

    if (docSnap.data() === undefined || docSnap.data().EVENT_DISABLED)
      return null;

    return docSnap.data();
  } catch (error) {
    console.error(error);
  }
};

export const getGroupEventsCount = async (GROUP_ID) => {
  try {
    const q = query(
      collection(db, EVENTS_COLLECTION),
      where('EVENT_POSTED_BY_ID', '==', GROUP_ID),
      where('EVENT_DISABLED', '==', false)
    );

    const snapshot = await getCountFromServer(q);

    return snapshot.data().count;
  } catch (error) {
    console.error(error);
    return 0;
  }
};

export const getGroupPendingApprovalEventsCount = async (GROUP_ID) => {
  try {
    const q = query(
      collection(db, EVENTS_COLLECTION),
      where('EVENT_POSTED_BY_ID', '==', GROUP_ID),
      where('EVENT_APPROVED', '==', false),
      where('EVENT_APPROVED_REVIEWED', '==', false),
      where('EVENT_DISABLED', '==', false)
    );

    const snapshot = await getCountFromServer(q);

    return snapshot.data().count;
  } catch (error) {
    console.error(error);
    return 0;
  }
};

export const getGroupTotalEventVisibilityCount = async (
  GROUP_ID,
  start_date,
  end_date
) => {
  try {
    const public_visibility_q = query(
      collection(db, EVENTS_COLLECTION),
      where('EVENT_POSTED_BY_ID', '==', GROUP_ID),
      where('EVENT_DATETIME', '>', start_date),
      where('EVENT_DATETIME', '<', end_date),
      where('EVENT_VISIBILITY', '==', 'public'),
      where('EVENT_DISABLED', '==', false)
    );

    const public_visibility_snapshot = await getCountFromServer(
      public_visibility_q
    );
    const public_visibility_count = public_visibility_snapshot.data().count;

    const private_visibility_q = query(
      collection(db, EVENTS_COLLECTION),
      where('EVENT_POSTED_BY_ID', '==', GROUP_ID),
      where('EVENT_DATETIME', '>', start_date),
      where('EVENT_DATETIME', '<', end_date),
      where('EVENT_VISIBILITY', '==', 'private'),
      where('EVENT_DISABLED', '==', false)
    );

    const private_visibility_snapshot = await getCountFromServer(
      private_visibility_q
    );
    const private_visibility_count = private_visibility_snapshot.data().count;

    // return {
    //   public: 10,
    //   private: 0,
    // };

    return {
      public: public_visibility_count,
      private: private_visibility_count,
    };
  } catch (error) {
    console.error(error);
    return {
      public: 0,
      private: 0,
    };
  }
};

export const getGroupTotalEventFormatCount = async (
  GROUP_ID,
  start_date,
  end_date
) => {
  try {
    const inperson_format_q = query(
      collection(db, EVENTS_COLLECTION),
      where('EVENT_POSTED_BY_ID', '==', GROUP_ID),
      where('EVENT_DATETIME', '>', start_date),
      where('EVENT_DATETIME', '<', end_date),
      where('EVENT_FORMAT', '==', 'inperson'),
      where('EVENT_DISABLED', '==', false)
    );

    const inperson_format_snapshot = await getCountFromServer(
      inperson_format_q
    );
    const inperson_format_count = inperson_format_snapshot.data().count;

    //

    const online_format_q = query(
      collection(db, EVENTS_COLLECTION),
      where('EVENT_POSTED_BY_ID', '==', GROUP_ID),
      where('EVENT_DATETIME', '>', start_date),
      where('EVENT_DATETIME', '<', end_date),
      where('EVENT_FORMAT', '==', 'online'),
      where('EVENT_DISABLED', '==', false)
    );

    const online_format_snapshot = await getCountFromServer(online_format_q);
    const online_format_count = online_format_snapshot.data().count;

    //

    const hybrid_format_q = query(
      collection(db, EVENTS_COLLECTION),
      where('EVENT_POSTED_BY_ID', '==', GROUP_ID),
      where('EVENT_DATETIME', '>', start_date),
      where('EVENT_DATETIME', '<', end_date),
      where('EVENT_FORMAT', '==', 'hybrid'),
      where('EVENT_DISABLED', '==', false)
    );

    const hybrid_format_snapshot = await getCountFromServer(hybrid_format_q);
    const hybrid_format_count = hybrid_format_snapshot.data().count;

    // return {
    //   inperson: 10,
    //   online: 10,
    //   hybrid: 10,
    // };

    return {
      inperson: inperson_format_count,
      online: online_format_count,
      hybrid: hybrid_format_count,
    };
  } catch (error) {
    console.error(error);
    return {
      inperson: 0,
      online: 0,
      hybrid: 0,
    };
  }
};

export const getGroupTotalEventFeedbackCount = async ({
  GROUP_ID,
  start_date,
  end_date,
  result_limit = 10,
}) => {
  try {
    var return_array_data = [];

    const event_doc_query = query(
      collection(db, EVENTS_COLLECTION),
      where('EVENT_POSTED_BY_ID', '==', GROUP_ID),
      where('EVENT_DATETIME', '>', start_date),
      where('EVENT_DATETIME', '<', end_date),
      where('EVENT_DISABLED', '==', false),
      limit(result_limit)
    );

    const querySnapshot = await getDocs(event_doc_query);

    if (querySnapshot.empty) {
      return [];
    }

    // eslint-disable-next-line no-loop-func
    querySnapshot.forEach(async (queryDoc) => {
      return_array_data.push({
        name: queryDoc.data().EVENT_TITLE,
        Going: queryDoc.data().NUM_GOING,
        Interested: queryDoc.data().NUM_INTERESTED,
        Invited: queryDoc.data().USER_ID_INVITED_ALL?.length,
      });
    });

    return return_array_data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getEventGoingCount = async (EVENT_ID) => {
  try {
    const q = query(
      collection(db, USERS_COLLECTION),
      where('GOING_EVENTS', 'array-contains', EVENT_ID),
      where('USER_ROLE', '==', 'user'),
      where('USER_DISABLED', '==', false)
    );

    const snapshot = await getCountFromServer(q);

    return snapshot.data().count;
  } catch (error) {
    console.error(error);
    return 0;
  }
};

export const getEventInterestedCount = async (EVENT_ID) => {
  try {
    const q = query(
      collection(db, USERS_COLLECTION),
      where('INTERESTED_EVENTS', 'array-contains', EVENT_ID),
      where('USER_ROLE', '==', 'user'),
      where('USER_DISABLED', '==', false)
    );

    const snapshot = await getCountFromServer(q);

    return snapshot.data().count;
  } catch (error) {
    console.error(error);
    return 0;
  }
};

export const getEventTotalInviteCount = async (EVENT_ID) => {
  try {
    const q = query(
      collection(db, USERS_COLLECTION),
      where('ALL_INVITED_EVENTS', 'array-contains', EVENT_ID),
      where('USER_ROLE', '==', 'user'),
      where('USER_DISABLED', '==', false)
    );

    const snapshot = await getCountFromServer(q);

    return snapshot.data().count;
  } catch (error) {
    console.error(error);
    return 0;
  }
};

export const getEventPendingResponseInviteCount = async (EVENT_ID) => {
  try {
    const q = query(
      collection(db, USERS_COLLECTION),
      where('INVITED_EVENTS_PENDING_RESPONSE', 'array-contains', EVENT_ID),
      where('USER_ROLE', '==', 'user'),
      where('USER_DISABLED', '==', false)
    );

    const snapshot = await getCountFromServer(q);

    return snapshot.data().count;
  } catch (error) {
    console.error(error);
    return 0;
  }
};

export const loadGroupEventsListWithPagination = async ({
  lastEventId,
  GROUP_ID,
  results_limit = 10,
  direction = CONSTANTS.FETCH_DIRECTION.NEXT,
}) => {
  let docs = [];
  let newLastEventId = null;
  let error = null;
  let batch;

  let status = 'UNDETERMINED';

  try {
    if (lastEventId) {
      const lastDoc = await getDoc(doc(db, EVENTS_COLLECTION, lastEventId));

      if (direction === CONSTANTS.FETCH_DIRECTION.NEXT) {
        batch = query(
          collection(db, EVENTS_COLLECTION),
          orderBy('EVENT_DATETIME', 'desc'),
          orderBy('EVENT_TYPE'),
          orderBy('NUM_GOING'),
          startAfter(lastDoc),
          limit(results_limit),
          where('EVENT_POSTED_BY_ID', '==', GROUP_ID),
          where('EVENT_DISABLED', '==', false)
        );
      } else if (direction === CONSTANTS.FETCH_DIRECTION.PREVIOUS) {
        batch = query(
          collection(db, EVENTS_COLLECTION),
          orderBy('EVENT_DATETIME', 'desc'),
          orderBy('EVENT_TYPE'),
          orderBy('NUM_GOING'),
          endAt(lastDoc),
          limit(results_limit),
          where('EVENT_POSTED_BY_ID', '==', GROUP_ID),
          where('EVENT_DISABLED', '==', false)
        );
      }
    } else {
      batch = query(
        collection(db, EVENTS_COLLECTION),
        orderBy('EVENT_DATETIME', 'desc'),
        orderBy('EVENT_TYPE'),
        orderBy('NUM_GOING'),
        limit(results_limit),
        where('EVENT_POSTED_BY_ID', '==', GROUP_ID),
        where('EVENT_DISABLED', '==', false)
      );
    }

    status = 'PENDING';
    const snapshots = await getDocs(batch);

    newLastEventId =
      snapshots.docs[snapshots.docs.length - 1]?.data()?.EVENT_ID || null;

    docs = extractEventsSnapshots(snapshots);
    status = 'SUCCESS';

    return {
      status,
      error,
      docs,
      lastEventId: newLastEventId,
    };
  } catch (error) {
    status = 'FAILED';
    return {
      status,
      error: error,
      docs,
      lastEventId: newLastEventId,
    };
  }
};

export const loadEventGoingListWithPagination = async ({
  lastUserId,
  EVENT_ID,
  results_limit = 10,
}) => {
  let docs = [];
  let newLastUserId = null;
  let error = null;
  let batch;

  let status = 'UNDETERMINED';

  try {
    if (lastUserId) {
      const lastDoc = await getDoc(doc(db, USERS_COLLECTION, lastUserId));

      batch = query(
        collection(db, 'users'),
        orderBy('FIRST_NAME'),
        orderBy('LAST_NAME'),
        startAfter(lastDoc),
        limit(results_limit),
        where('GOING_EVENTS', 'array-contains', EVENT_ID),
        where('USER_ROLE', '==', 'user'),
        where('USER_DISABLED', '==', false)
      );
    } else {
      batch = query(
        collection(db, 'users'),
        orderBy('FIRST_NAME'),
        orderBy('LAST_NAME'),
        limit(results_limit),
        where('GOING_EVENTS', 'array-contains', EVENT_ID),
        where('USER_ROLE', '==', 'user'),
        where('USER_DISABLED', '==', false)
      );
    }

    status = 'PENDING';
    const snapshots = await getDocs(batch);

    newLastUserId =
      snapshots.docs[snapshots.docs.length - 1]?.data()?.USER_ID || null;

    docs = extractSnapshots(snapshots);
    status = 'SUCCESS';

    return {
      status,
      error,
      docs,
      lastUserId: newLastUserId,
    };
  } catch (error) {
    status = 'FAILED';
    return {
      status,
      error: error,
      docs,
      lastUserId: newLastUserId,
    };
  }
};

export const loadEventInterestedListWithPagination = async ({
  lastUserId,
  EVENT_ID,
  results_limit = 10,
}) => {
  let docs = [];
  let newLastUserId = null;
  let error = null;
  let batch;

  let status = 'UNDETERMINED';

  try {
    if (lastUserId) {
      const lastDoc = await getDoc(doc(db, USERS_COLLECTION, lastUserId));

      batch = query(
        collection(db, 'users'),
        orderBy('FIRST_NAME'),
        orderBy('LAST_NAME'),
        startAfter(lastDoc),
        limit(results_limit),
        where('INTERESTED_EVENTS', 'array-contains', EVENT_ID),
        where('USER_ROLE', '==', 'user'),
        where('USER_DISABLED', '==', false)
      );
    } else {
      batch = query(
        collection(db, 'users'),
        orderBy('FIRST_NAME'),
        orderBy('LAST_NAME'),
        limit(results_limit),
        where('INTERESTED_EVENTS', 'array-contains', EVENT_ID),
        where('USER_ROLE', '==', 'user'),
        where('USER_DISABLED', '==', false)
      );
    }

    status = 'PENDING';
    const snapshots = await getDocs(batch);

    newLastUserId =
      snapshots.docs[snapshots.docs.length - 1]?.data()?.USER_ID || null;

    docs = extractSnapshots(snapshots);
    status = 'SUCCESS';

    return {
      status,
      error,
      docs,
      lastUserId: newLastUserId,
    };
  } catch (error) {
    status = 'FAILED';
    return {
      status,
      error: error,
      docs,
      lastUserId: newLastUserId,
    };
  }
};

export const loadEventInvitedListWithPagination = async ({
  lastUserId,
  EVENT_ID,
  results_limit = 10,
}) => {
  let docs = [];
  let newLastUserId = null;
  let error = null;
  let batch;

  let status = 'UNDETERMINED';

  try {
    if (lastUserId) {
      const lastDoc = await getDoc(doc(db, USERS_COLLECTION, lastUserId));

      batch = query(
        collection(db, 'users'),
        orderBy('FIRST_NAME'),
        orderBy('LAST_NAME'),
        startAfter(lastDoc),
        limit(results_limit),
        where('ALL_INVITED_EVENTS', 'array-contains', EVENT_ID),
        where('USER_ROLE', '==', 'user'),
        where('USER_DISABLED', '==', false)
      );
    } else {
      batch = query(
        collection(db, 'users'),
        orderBy('FIRST_NAME'),
        orderBy('LAST_NAME'),
        limit(results_limit),
        where('ALL_INVITED_EVENTS', 'array-contains', EVENT_ID),
        where('USER_ROLE', '==', 'user'),
        where('USER_DISABLED', '==', false)
      );
    }

    status = 'PENDING';
    const snapshots = await getDocs(batch);

    newLastUserId =
      snapshots.docs[snapshots.docs.length - 1]?.data()?.USER_ID || null;

    docs = extractSnapshots(snapshots);
    status = 'SUCCESS';

    return {
      status,
      error,
      docs,
      lastUserId: newLastUserId,
    };
  } catch (error) {
    status = 'FAILED';
    return {
      status,
      error: error,
      docs,
      lastUserId: newLastUserId,
    };
  }
};

export const deleteEventsFromEventObjectArray = async (
  event_obj_array,
  GROUP_ID
) => {
  try {
    var batchActions = [];

    const group_id_ref = doc(db, GROUPS_COLLECTION, GROUP_ID);

    for (let event_obj of event_obj_array) {
      const event_id_ref = doc(db, EVENTS_COLLECTION, event_obj.EVENT_ID);

      batchActions.push({
        type: 'update',
        ref: group_id_ref,
        data: {
          POSTED_EVENTS: arrayRemove(event_obj.EVENT_ID),
        },
      });

      if (event_obj.USER_ID_INVITED && event_obj.USER_ID_INVITED.length > 0) {
        for (let user_id of event_obj.USER_ID_INVITED) {
          const user_id_ref = doc(db, USERS_COLLECTION, user_id);

          batchActions.push({
            type: 'update',
            ref: user_id_ref,
            data: {
              ALL_INVITED_EVENTS: arrayRemove(event_obj.EVENT_ID),
              INVITED_EVENTS_RESPONDED: arrayRemove(event_obj.EVENT_ID),
              INVITED_EVENTS_PENDING_RESPONSE: arrayRemove(event_obj.EVENT_ID),
            },
          });
        }
      }

      for (let user_id of event_obj.USER_ID_GOING) {
        const user_id_ref = doc(db, USERS_COLLECTION, user_id);

        batchActions.push({
          type: 'update',
          ref: user_id_ref,
          data: {
            GOING_EVENTS: arrayRemove(event_obj.EVENT_ID),
          },
        });
      }

      for (let user_id of event_obj.USER_ID_INTERESTED) {
        const user_id_ref = doc(db, USERS_COLLECTION, user_id);

        batchActions.push({
          type: 'update',
          ref: user_id_ref,
          data: {
            INTERESTED_EVENTS: arrayRemove(event_obj.EVENT_ID),
          },
        });
      }

      batchActions.push({
        type: 'delete',
        ref: event_id_ref,
      });
    }

    await executeBatchActions(batchActions).then(async () => {
      for (let event_obj of event_obj_array) {
        await deleteEventMediaFromStorage(event_obj.EVENT_MEDIA_PATH);
      }
    });

    return CONSTANTS.DB_RETURN_CODES.SUCCESS;
  } catch (error) {
    console.error(error);
    return CONSTANTS.DB_RETURN_CODES.FAILED;
  }
};

/////////////////////// MARKETING GROUP INVITES COLLECTION /////////////////////

export const addMarketingGroupInvite = async (email, group_id, role) => {
  try {
    const createdAt = new Date();

    await setDoc(doc(collection(db, MARKETING_GROUP_INVITES_COLLECTION)), {
      EMAIL_ADDRESS: email,
      GROUP_ID: group_id,
      ROLE: role,
      DATETIME_CREATED: createdAt,
    });

    return CONSTANTS.DB_RETURN_CODES.SUCCESS;
  } catch (error) {
    console.error(error);
    return CONSTANTS.DB_RETURN_CODES.FAILED;
  }
};

///////////////////////////// CSV UPLOADING /////////////////////////////

async function convertToAmPm(dateTimeString) {
  const regex24Hour =
    /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/(\d{2}|\d{4}) (0?[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/;
  const match24Hour = dateTimeString.match(regex24Hour);

  if (match24Hour) {
    const [, month, day, year, hours, minutes] = match24Hour;
    const hour = parseInt(hours, 10);

    if (hour >= 0 && hour <= 23) {
      const amPm = hour < 12 ? 'am' : 'pm';
      const convertedTime = `${month}/${day}/${year} ${
        hour % 12 || 12
      }:${minutes} ${amPm}`;
      return convertedTime;
    }
  }

  return dateTimeString;
}

async function getAddressFromCoordinates(latitude, longitude) {
  const response = await fetch(
    'https://maps.googleapis.com/maps/api/geocode/json?address=' +
      latitude +
      ',' +
      longitude +
      '&key=' +
      GOOGLE_MAPS_API_KEY
  );
  const responseJson = await response.json();

  if (responseJson.status === 'OK') {
    return responseJson?.results?.[0]?.formatted_address;
  } else {
    return 'Location Not Found'; // not found
  }
}

async function getCoordinatesFromAddress(address) {
  const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
    address
  )}&key=${GOOGLE_MAPS_API_KEY}`;

  const response = await fetch(apiUrl);

  const data = await response.json();

  if (data.status === 'OK' && data.results.length > 0) {
    const location = data.results[0].geometry.location;
    return {
      latitude: location.lat,
      longitude: location.lng,
    };
  } else {
    console.error('Address not found or API error.');
    return null;
  }
}

// member csv upload
export const uploadMembersCSVToDB = async (
  csv_data,
  group_object,
  mapData,
  groupAdminName
) => {
  try {
    var data = csv_data.data;

    const GROUP_ID = group_object.GROUP_ID;
    const group_id_ref = doc(db, GROUPS_COLLECTION, GROUP_ID);

    data.shift(); // removes header row

    const role_options = ['admin', 'member'];

    var batchActions = [];

    var failedEntries = [];

    for (let CSV_row of data) {
      var DB_OBJECT_SCHEME = {
        group_role: '',
        EMAIL_ADDRESS: '',
        FIRST_NAME: '',
        MIDDLE_NAME: '',
        LAST_NAME: '',
        PHONE_NUMBER: '',
        USER_ID: '',
      };

      if (
        CSV_row &&
        CSV_row?.length >= 2 &&
        CSV_row[0] !== '' &&
        CSV_row[1] !== ''
      ) {
        DB_OBJECT_SCHEME.EMAIL_ADDRESS = CSV_row[0]
          .toLowerCase()
          .replace(/\s/g, '');
        DB_OBJECT_SCHEME.group_role = CSV_row[1]
          .toLowerCase()
          .replace(/\s/g, '');

        if (role_options.indexOf(DB_OBJECT_SCHEME.group_role) === -1) {
          // invalid role
          failedEntries.push({
            'Email Address (REQUIRED)': DB_OBJECT_SCHEME.EMAIL_ADDRESS,
            'Group Role (REQUIRED)': DB_OBJECT_SCHEME.group_role,
            'First Name (OPTIONAL)': DB_OBJECT_SCHEME.FIRST_NAME,
            'Middle Name (OPTIONAL)': DB_OBJECT_SCHEME.MIDDLE_NAME,
            'Last Name (OPTIONAL)': DB_OBJECT_SCHEME.LAST_NAME,
            'Phone Number (OPTIONAL)': DB_OBJECT_SCHEME.PHONE_NUMBER,
            Reason: 'Invalid group role',
          });
          continue;
        }

        // optional fields
        if (CSV_row.length >= 3 && CSV_row[2] !== '')
          DB_OBJECT_SCHEME.FIRST_NAME = CSV_row[2].trim();
        if (CSV_row.length >= 4 && CSV_row[3] !== '')
          DB_OBJECT_SCHEME.MIDDLE_NAME = CSV_row[3].trim();
        if (CSV_row.length >= 5 && CSV_row[4] !== '')
          DB_OBJECT_SCHEME.LAST_NAME = CSV_row[4].trim();
        if (CSV_row.length >= 6 && CSV_row[5] !== '')
          DB_OBJECT_SCHEME.PHONE_NUMBER = CSV_row[5];

        // check if valid domain
        var email_domain = DB_OBJECT_SCHEME.EMAIL_ADDRESS.split('@')[1];

        if (
          email_domain !== CONSTANTS.URLS.DOMAIN &&
          !mapData.EMAIL_DOMAINS.includes(email_domain)
        ) {
          // email domain not allowed for group
          failedEntries.push({
            'Email Address (REQUIRED)': DB_OBJECT_SCHEME.EMAIL_ADDRESS,
            'Group Role (REQUIRED)': DB_OBJECT_SCHEME.group_role,
            'First Name (OPTIONAL)': DB_OBJECT_SCHEME.FIRST_NAME,
            'Middle Name (OPTIONAL)': DB_OBJECT_SCHEME.MIDDLE_NAME,
            'Last Name (OPTIONAL)': DB_OBJECT_SCHEME.LAST_NAME,
            'Phone Number (OPTIONAL)': DB_OBJECT_SCHEME.PHONE_NUMBER,
            Reason: 'Email domain not allowed for group',
          });
          continue;
        }

        // check if member that's being added is already a Mixer user
        const user_doc_query = query(
          collection(db, USERS_COLLECTION),
          where('EMAIL_ADDRESS', '==', DB_OBJECT_SCHEME.EMAIL_ADDRESS)
        );

        const querySnapshot = await getDocs(user_doc_query);

        if (querySnapshot.empty) {
          // member that's being added is not already a Mixer user

          // adding to marketing group invites collection
          const createdAt = new Date();

          const marketing_group_invite_collection_ref = doc(
            collection(db, MARKETING_GROUP_INVITES_COLLECTION)
          );

          batchActions.push({
            type: 'set',
            ref: marketing_group_invite_collection_ref,
            data: {
              EMAIL_ADDRESS: DB_OBJECT_SCHEME.EMAIL_ADDRESS,
              FIRST_NAME: DB_OBJECT_SCHEME.FIRST_NAME,
              MIDDLE_NAME: DB_OBJECT_SCHEME.MIDDLE_NAME,
              LAST_NAME: DB_OBJECT_SCHEME.LAST_NAME,
              PHONE_NUMBER: DB_OBJECT_SCHEME.PHONE_NUMBER,
              GROUP_ID: GROUP_ID,
              ROLE: DB_OBJECT_SCHEME.group_role,
              DATETIME_CREATED: createdAt,
            },
          });

          // send email to new user
          const batchRes =
            await groupInviteNotRegisteredUserEmailDataForCSVBatch(
              DB_OBJECT_SCHEME.EMAIL_ADDRESS,
              DB_OBJECT_SCHEME.group_role,
              groupAdminName,
              group_object,
              mapData
            );

          batchActions.push(batchRes);
        } else {
          // member that's being added is already a Mixer user
          // eslint-disable-next-line no-loop-func
          querySnapshot.forEach(async (queryDoc) => {
            const USER_ID = queryDoc.data().USER_ID;
            DB_OBJECT_SCHEME.USER_ID = USER_ID;
            const user_doc_ref = doc(db, USERS_COLLECTION, USER_ID);

            if (DB_OBJECT_SCHEME.group_role === 'admin') {
              batchActions.push({
                type: 'update',
                ref: group_id_ref,
                data: {
                  MEMBERS: arrayRemove(USER_ID),
                  ADMINS: arrayUnion(USER_ID),
                },
              });

              batchActions.push({
                type: 'update',
                ref: user_doc_ref,
                data: {
                  MEMBER_GROUPS: arrayRemove(GROUP_ID),
                  ADMIN_GROUPS: arrayUnion(GROUP_ID),
                },
              });
            } else if (DB_OBJECT_SCHEME.group_role === 'member') {
              batchActions.push({
                type: 'update',
                ref: group_id_ref,
                data: {
                  ADMINS: arrayRemove(USER_ID),
                  MEMBERS: arrayUnion(USER_ID),
                },
              });

              batchActions.push({
                type: 'update',
                ref: user_doc_ref,
                data: {
                  ADMIN_GROUPS: arrayRemove(GROUP_ID),
                  MEMBER_GROUPS: arrayUnion(GROUP_ID),
                },
              });
            }
          });
        }
      }
    }

    await executeBatchActions(batchActions);

    return {
      code: CONSTANTS.DB_RETURN_CODES.SUCCESS,
      failedRows: failedEntries,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {
      code: CONSTANTS.DB_RETURN_CODES.FAILED,
      failedRows: failedEntries,
      error,
    };
  }
};

// event csv upload
export const uploadEventsCSVToDB = async (csv_data, group_object, mapData) => {
  try {
    var data = csv_data.data;

    const GROUP_ID = group_object.GROUP_ID;
    const group_id_ref = doc(db, GROUPS_COLLECTION, GROUP_ID);

    data.shift(); // removes header row

    const toggle_options = ['yes', 'no'];
    const visibility_options = ['public', 'private'];
    const format_options = ['inperson', 'virtual', 'hybrid'];

    // const date_time_format = 'MM/DD/YYYY hh:mm am';
    const date_time_format_regex =
      /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/(\d{2}|\d{4}) (0?[0-9]|1[0-9]|2[0-3]):([0-5][0-9]) (am|pm)$/i;

    // const duration_format = 'hh:mm';
    const duration_format_regex = /^(0?[0-9]|1[0-2]):([0-5][0-9])$/;

    var batchActions = [];
    var failedEntries = [];
    var successEntries = [];

    function addToFailedEntries(DB_OBJECT_SCHEME, reason) {
      failedEntries.push({
        'Title (REQUIRED)': DB_OBJECT_SCHEME.EVENT_TITLE,
        'Description (REQUIRED)': DB_OBJECT_SCHEME.EVENT_DESCRIPTION,
        'Visibility (REQUIRED)': DB_OBJECT_SCHEME.EVENT_VISIBILITY,
        'Capacity (OPTIONAL)': DB_OBJECT_SCHEME.EVENT_CAPACITY,
        'Date/Time (REQUIRED)': DB_OBJECT_SCHEME.EVENT_DATETIME,
        'All Day (OPTIONAL)': DB_OBJECT_SCHEME.EVENT_ALL_DAY,
        'Duration (REQUIRED)': DB_OBJECT_SCHEME.EVENT_DURATION,
        'Format (REQUIRED)': DB_OBJECT_SCHEME.EVENT_FORMAT,
        'Online Link (REQUIRED if hybrid or online)':
          DB_OBJECT_SCHEME.EVENT_ONLINE_LINK,
        'Location Details (OPTIONAL)': DB_OBJECT_SCHEME.EVENT_LOCATION_DETAILS,
        'Address (REQUIRED if in person or hybrid)':
          DB_OBJECT_SCHEME.EVENT_ADDRESS,
        'Latitude Coordinates (OPTIONAL)': DB_OBJECT_SCHEME.EVENT_COOR_LAT,
        'Longitude Coordinates (OPTIONAL)': DB_OBJECT_SCHEME.EVENT_COOR_LNG,
        'Food Served (OPTIONAL)': DB_OBJECT_SCHEME.EVENT_FOOD_SERVED,
        'Ticketed (OPTIONAL)': DB_OBJECT_SCHEME.EVENT_TICKETED,
        Reason: reason,
      });
    }

    for (let CSV_row of data) {
      var DB_OBJECT_SCHEME = {
        EVENT_TITLE: CSV_row[0].trim(),
        EVENT_DESCRIPTION: CSV_row[1],
        EVENT_VISIBILITY: CSV_row[2].toLowerCase().trim(),
        EVENT_CAPACITY: CSV_row[3].trim() || 0,
        EVENT_DATETIME: CSV_row[4].trim(),
        EVENT_DATETIME_FORMATTED: null,
        EVENT_ALL_DAY: CSV_row[5].toLowerCase().replace(/\s/g, '') || 'no',
        EVENT_ALL_DAY_BOOL: false,
        EVENT_DURATION: CSV_row[6].trim(),
        EVENT_FORMAT: CSV_row[7].toLowerCase().replace(/\s/g, '').trim(),
        EVENT_ONLINE_LINK: CSV_row[8].trim(),
        EVENT_LOCATION_DETAILS: CSV_row[9].trim(),
        EVENT_ADDRESS: CSV_row[10].trim(),
        EVENT_COOR_LAT: CSV_row[11].trim(),
        EVENT_COOR_LNG: CSV_row[12].trim(),
        EVENT_GEO_HASH: '',
        EVENT_GEO_POINT: {},
        EVENT_FOOD_SERVED: CSV_row[13].toLowerCase().replace(/\s/g, '') || 'no',
        EVENT_FOOD_SERVED_BOOL: false,
        EVENT_TICKETED: CSV_row[14].toLowerCase().replace(/\s/g, '') || 'no',
        EVENT_TICKETED_BOOL: false,
        EVENT_POSTED_BY_ID: GROUP_ID,
        EVENT_ID: mapData?.UNIT_ID + '_e' + RandomCodeGenerator(10),
        UNIT_ID: mapData?.UNIT_ID,
        EVENT_APPROVED: false,
      };

      // required fields check
      if (
        CSV_row &&
        CSV_row?.length >= 8 &&
        DB_OBJECT_SCHEME.EVENT_TITLE !== '' &&
        DB_OBJECT_SCHEME.EVENT_DESCRIPTION !== '' &&
        DB_OBJECT_SCHEME.EVENT_VISIBILITY !== '' &&
        DB_OBJECT_SCHEME.EVENT_DATETIME !== '' &&
        (DB_OBJECT_SCHEME.EVENT_ALL_DAY === 'yes' ||
          (DB_OBJECT_SCHEME.EVENT_ALL_DAY === 'no' &&
            DB_OBJECT_SCHEME.EVENT_DURATION !== '')) &&
        DB_OBJECT_SCHEME.EVENT_FORMAT !== '' &&
        (DB_OBJECT_SCHEME.EVENT_FORMAT === 'inperson' ||
          DB_OBJECT_SCHEME.EVENT_ONLINE_LINK !== '') &&
        (DB_OBJECT_SCHEME.EVENT_FORMAT === 'virtual' ||
          (DB_OBJECT_SCHEME.EVENT_FORMAT !== 'virtual' &&
            (DB_OBJECT_SCHEME.EVENT_ADDRESS !== '' ||
              (DB_OBJECT_SCHEME.EVENT_COOR_LAT !== '' &&
                DB_OBJECT_SCHEME.EVENT_COOR_LNG !== ''))))
      ) {
        // enforced character limits
        if (
          DB_OBJECT_SCHEME.EVENT_TITLE.length > CHARACTER_LIMITS.EVENT_TITLE
        ) {
          addToFailedEntries(
            DB_OBJECT_SCHEME,
            'Title has exceeded character limit'
          );
          continue;
        }

        if (
          DB_OBJECT_SCHEME.EVENT_DESCRIPTION.length >
          CHARACTER_LIMITS.EVENT_DESCRIPTION
        ) {
          addToFailedEntries(
            DB_OBJECT_SCHEME,
            'Description has exceeded character limit'
          );
          continue;
        }

        if (
          DB_OBJECT_SCHEME.EVENT_ONLINE_LINK.length >
          CHARACTER_LIMITS.EVENT_ONLINE_LINK
        ) {
          addToFailedEntries(
            DB_OBJECT_SCHEME,
            'Online Link has exceeded character limit'
          );
          continue;
        }

        if (
          DB_OBJECT_SCHEME.EVENT_LOCATION_DETAILS.length >
          CHARACTER_LIMITS.EVENT_LOCATION_DETAILS
        ) {
          addToFailedEntries(
            DB_OBJECT_SCHEME,
            'Location Details has exceeded character limit'
          );
          continue;
        }

        // enforced options lists
        if (toggle_options.indexOf(DB_OBJECT_SCHEME.EVENT_ALL_DAY) === -1) {
          addToFailedEntries(DB_OBJECT_SCHEME, 'Invalid All Day option');
          continue;
        }

        if (toggle_options.indexOf(DB_OBJECT_SCHEME.EVENT_FOOD_SERVED) === -1) {
          addToFailedEntries(DB_OBJECT_SCHEME, 'Invalid Food Served option');
          continue;
        }

        if (toggle_options.indexOf(DB_OBJECT_SCHEME.EVENT_TICKETED) === -1) {
          addToFailedEntries(DB_OBJECT_SCHEME, 'Invalid Ticketed option');
          continue;
        }

        if (
          visibility_options.indexOf(DB_OBJECT_SCHEME.EVENT_VISIBILITY) === -1
        ) {
          addToFailedEntries(DB_OBJECT_SCHEME, 'Invalid Visibility option');
          continue;
        }

        if (format_options.indexOf(DB_OBJECT_SCHEME.EVENT_FORMAT) === -1) {
          addToFailedEntries(DB_OBJECT_SCHEME, 'Invalid Format option');
          continue;
        }

        // enforced date/time & duration templates
        DB_OBJECT_SCHEME.EVENT_DATETIME_FORMATTED = await convertToAmPm(
          DB_OBJECT_SCHEME.EVENT_DATETIME
        );

        if (
          !date_time_format_regex.test(
            DB_OBJECT_SCHEME.EVENT_DATETIME_FORMATTED
          )
        ) {
          addToFailedEntries(DB_OBJECT_SCHEME, 'Invalid Date/Time format');
          continue;
        }

        if (!duration_format_regex.test(DB_OBJECT_SCHEME.EVENT_DURATION)) {
          addToFailedEntries(DB_OBJECT_SCHEME, 'Invalid Duration format');
          continue;
        }

        // convert yes/no to T/F
        if (DB_OBJECT_SCHEME.EVENT_ALL_DAY === 'yes')
          DB_OBJECT_SCHEME.EVENT_ALL_DAY_BOOL = true;
        else DB_OBJECT_SCHEME.EVENT_ALL_DAY_BOOL = false;

        if (DB_OBJECT_SCHEME.EVENT_FOOD_SERVED === 'yes')
          DB_OBJECT_SCHEME.EVENT_FOOD_SERVED_BOOL = true;
        else DB_OBJECT_SCHEME.EVENT_FOOD_SERVED_BOOL = false;

        if (DB_OBJECT_SCHEME.EVENT_TICKETED === 'yes')
          DB_OBJECT_SCHEME.EVENT_TICKETED_BOOL = true;
        else DB_OBJECT_SCHEME.EVENT_TICKETED_BOOL = false;

        try {
          // convert capacity to number (if doesn't work, return failed row)
          DB_OBJECT_SCHEME.EVENT_CAPACITY = parseInt(
            DB_OBJECT_SCHEME.EVENT_CAPACITY
          );

          if (isNaN(DB_OBJECT_SCHEME.EVENT_CAPACITY)) {
            addToFailedEntries(DB_OBJECT_SCHEME, 'Invalid Capacity format');
            continue;
          }
        } catch (error) {
          addToFailedEntries(DB_OBJECT_SCHEME, 'Invalid Capacity format');
          continue;
        }

        // convert coordinates to address or address to coordinates (vice versa)
        if (
          DB_OBJECT_SCHEME.EVENT_FORMAT !== 'virtual' &&
          DB_OBJECT_SCHEME.EVENT_COOR_LAT !== '' &&
          DB_OBJECT_SCHEME.EVENT_COOR_LNG !== ''
        ) {
          const address = await getAddressFromCoordinates(
            DB_OBJECT_SCHEME.EVENT_COOR_LAT,
            DB_OBJECT_SCHEME.EVENT_COOR_LNG
          );

          if (address) DB_OBJECT_SCHEME.EVENT_ADDRESS = address;
          else {
            addToFailedEntries(DB_OBJECT_SCHEME, 'Invalid Coordinates');
            continue;
          }
        } else if (
          DB_OBJECT_SCHEME.EVENT_FORMAT !== 'virtual' &&
          DB_OBJECT_SCHEME.EVENT_ADDRESS !== ''
        ) {
          const coordinates = await getCoordinatesFromAddress(
            DB_OBJECT_SCHEME.EVENT_ADDRESS
          );

          if (coordinates) {
            DB_OBJECT_SCHEME.EVENT_COOR_LAT = coordinates.latitude;
            DB_OBJECT_SCHEME.EVENT_COOR_LNG = coordinates.longitude;
          } else {
            addToFailedEntries(DB_OBJECT_SCHEME, 'Invalid Address');
            continue;
          }
        }

        if (mapData.GROUP_APPROVAL_NEEDED === false) {
          DB_OBJECT_SCHEME.EVENT_APPROVED = true;
        }

        var duration;
        var datetime;
        var time_zone_offset;
        var UTC_datetime;

        const durationHour = parseInt(
          DB_OBJECT_SCHEME.EVENT_DURATION.split(':')[0]
        );
        const durationMinute = parseInt(
          DB_OBJECT_SCHEME.EVENT_DURATION.split(':')[1]
        );

        // Convert date and time to UTC
        if (!DB_OBJECT_SCHEME.EVENT_ALL_DAY_BOOL) {
          // if not all day
          datetime = new Date(DB_OBJECT_SCHEME.EVENT_DATETIME_FORMATTED);
          UTC_datetime = new Date(
            Date.UTC(
              datetime.getUTCFullYear(),
              datetime.getUTCMonth(),
              datetime.getUTCDate(),
              datetime.getUTCHours(),
              datetime.getUTCMinutes(),
              datetime.getUTCSeconds(),
              datetime.getUTCMilliseconds()
            )
          );

          // Convert duration to time
          duration = new Date(
            UTC_datetime.getTime() +
              (durationHour * 3600 + durationMinute * 60) * 1000
          );
        } else {
          // if all day
          const today_midnight = new Date(
            DB_OBJECT_SCHEME.EVENT_DATETIME_FORMATTED
          );
          today_midnight.setUTCHours(0, 0, 0, 0);

          time_zone_offset = today_midnight.getTimezoneOffset() / 60;
          UTC_datetime = new Date(
            today_midnight.setTime(
              today_midnight.getTime() + time_zone_offset * 60 * 60 * 1000
            )
          );

          duration = new Date(
            UTC_datetime.getUTCFullYear(),
            UTC_datetime.getUTCMonth(),
            UTC_datetime.getUTCDate(),
            23,
            59,
            59
          );
        }

        var geo_loc_point;
        var geo_hash;

        if (
          DB_OBJECT_SCHEME?.EVENT_COOR_LAT &&
          DB_OBJECT_SCHEME?.EVENT_COOR_LNG
        ) {
          try {
            geo_loc_point = new GeoPoint(
              DB_OBJECT_SCHEME?.EVENT_COOR_LAT,
              DB_OBJECT_SCHEME?.EVENT_COOR_LNG
            );

            geo_hash = await geofire.geohashForLocation([
              parseFloat(DB_OBJECT_SCHEME?.EVENT_COOR_LAT),
              parseFloat(DB_OBJECT_SCHEME?.EVENT_COOR_LNG),
            ]);
          } catch (e) {
            console.error(e);
            addToFailedEntries(DB_OBJECT_SCHEME, 'Invalid Coordinates');
            continue;
          }
        } else {
          geo_loc_point = new GeoPoint(0, 0);
          geo_hash = '';
        }

        const event_to_post = {
          UNIT_ID: DB_OBJECT_SCHEME.UNIT_ID,
          EVENT_ID: DB_OBJECT_SCHEME.EVENT_ID,
          EVENT_MEDIA_PATH: '',
          GEO_LOC: geo_loc_point,
          GEO_HASH: geo_hash,
          EVENT_ADDRESS: DB_OBJECT_SCHEME.EVENT_ADDRESS,
          LOCATION_DETAILS: DB_OBJECT_SCHEME.EVENT_LOCATION_DETAILS,
          EVENT_TYPE: 'group',
          EVENT_TITLE: DB_OBJECT_SCHEME.EVENT_TITLE,
          EVENT_DESCRIPTION: DB_OBJECT_SCHEME.EVENT_DESCRIPTION,
          EVENT_VISIBILITY: DB_OBJECT_SCHEME.EVENT_VISIBILITY,
          EVENT_FOOD_SERVED: DB_OBJECT_SCHEME.EVENT_FOOD_SERVED_BOOL,
          EVENT_TICKETED: DB_OBJECT_SCHEME.EVENT_TICKETED_BOOL,
          EVENT_FORMAT: DB_OBJECT_SCHEME.EVENT_FORMAT,
          ONLINE_EVENT_LINK: DB_OBJECT_SCHEME.EVENT_ONLINE_LINK,
          EVENT_DATETIME: UTC_datetime,
          EVENT_DURATION: duration,
          EVENT_ALL_DAY: DB_OBJECT_SCHEME.EVENT_ALL_DAY_BOOL,
          EVENT_CAPACITY: DB_OBJECT_SCHEME.EVENT_CAPACITY,
          NUM_GOING: 0,
          USER_ID_GOING: [],
          NUM_INTERESTED: 0,
          USER_ID_INTERESTED: [],
          NUM_NOT_GOING: 0,
          USER_ID_NOT_GOING: [],
          USER_ID_CHECKED_IN: [],
          USER_ID_INVITED_ALL: [],
          USER_ID_INVITED_RESPONDED: [],
          USER_ID_INVITED_PENDING_RESPONSE: [],
          USER_ID_INVITATION_OWNERSHIP: [],
          PAST_EVENT: false,
          EVENT_POSTED_DATE: new Date(),
          EVENT_POSTED_BY_ID: GROUP_ID,
          EVENT_APPROVED: DB_OBJECT_SCHEME.EVENT_APPROVED,
          EVENT_APPROVED_REVIEWED: false,
          EVENT_APPROVED_BY: '',
          EVENT_APPROVED_DATE: null,
          EVENT_DISABLED: false,
          EVENT_DISABLED_BY: '',
          EVENT_DISABLED_REASON: '',
          EVENT_DISABLED_DATETIME: null,
          EVENT_VERIFIED: true,
        };

        const uploaded_event_image_path =
          await uploadGroupImageFileToEventImageStorage(
            DB_OBJECT_SCHEME.EVENT_ID,
            group_object.GROUP_IMG_PATH,
            event_to_post.EVENT_POSTED_DATE
          );

        event_to_post.EVENT_MEDIA_PATH = uploaded_event_image_path;

        batchActions.push({
          type: 'set',
          ref: doc(db, EVENTS_COLLECTION, DB_OBJECT_SCHEME.EVENT_ID),
          data: {
            ...event_to_post,
          },
        });

        batchActions.push({
          type: 'update',
          ref: group_id_ref,
          data: {
            POSTED_EVENTS: arrayUnion(DB_OBJECT_SCHEME.EVENT_ID),
          },
        });

        successEntries.push(DB_OBJECT_SCHEME);
      } else {
        addToFailedEntries(DB_OBJECT_SCHEME, 'Missing required fields');
        continue;
      }
    }

    await executeBatchActions(batchActions);

    return {
      code: CONSTANTS.DB_RETURN_CODES.SUCCESS,
      failedRows: failedEntries,
      successRows: successEntries,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {
      code: CONSTANTS.DB_RETURN_CODES.FAILED,
      failedRows: failedEntries,
      successRows: successEntries,
      error,
    };
  }
};
