import { React, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CookieConsent from 'react-cookie-consent';
import { isMobile, isIOS, isAndroid } from 'react-device-detect';

import {
  setCurrentUser,
  resetCurrentUser,
} from './store/auth-user/auth-user.action';

import { onAuthStateChangedListener } from './utils/firebase/auth-firebase.utils';
import { getUserMetadata } from './utils/firebase/db-firebase.utils';

import Spinner from './components/spinner/spinner.component';

import CONSTANTS from './infrastructure/config';

import './App.css';

const HomeRoute = lazy(() => import('./routes/home/home.route'));
const LoginRoute = lazy(() => import('./routes/login/login.route'));
const RegisterRoute = lazy(() => import('./routes/register/register.route'));
const ResetPasswordRoute = lazy(() =>
  import('./routes/reset-password/reset-password.route')
);
const NotFoundRoute = lazy(() => import('./routes/not-found/not-found.route'));
const TOSRoute = lazy(() => import('./routes/tos/tos.route'));
const PrivacyPolicyRoute = lazy(() =>
  import('./routes/privacy-policy/privacy-policy.route')
);
const WaitlistRoute = lazy(() => import('./routes/waitlist/waitlist.route'));
const ContactUsRoute = lazy(() =>
  import('./routes/contact-us/contact-us.route')
);
const DashboardRoute = lazy(() => import('./routes/dashboard/dashboard.route'));
const DownloadRoute = lazy(() => import('./routes/download/download.route'));
const DeepLinkQRCode = lazy(() =>
  import('./routes/deep-link-qr-code/deep-link-qr-code.route')
);
const AppStoreRoute = lazy(() => import('./routes/app-store/app-store.route'));
const GooglePlayRoute = lazy(() =>
  import('./routes/google-play/google-play.route')
);
const AdminRoute = lazy(() => import('./routes/admin/admin.route'));
const SupportRoute = lazy(() => import('./routes/support/support.route'));

/****** SUPPORTED ROUTES
        login.domain.com -> domain.com/login -> LoginRoute
        register.domain.com -> domain.com/register -> RegisterRoute
        waitlist.domain.com -> domain.com/waitlist -> WaitlistRoute
        dashboard.domain.com -> domain.com/dashboard -> DashboardRoute
        app-store.domain.com -> domain.com/app-store -> AppStoreRoute
        google-play.domain.com -> domain.com/google-play -> GooglePlayRoute
        admin.domain.com -> domain.com/admin -> AdminRoute
        download.domain.com -> domain.com/download -> DownloadRoute
        support.domain.com -> domain.com/support -> SupportRoute

        domain.com/reset-password -> ResetPasswordRoute
        domain.com -> HomeRoute
        domain.com/tos -> HomeRoute
        domain.com/privacy-policy -> HomeRoute
        domain.com/contact-us -> ContactUsRoute

        domain.com/404 -> NotFoundRoute (handles all invalid routes + auth-gated valid routes if not logged in)

        Deep Linking:
        domain.com/event/EVENT_ID -> in-app event page
        domain.com/group/GROUP_ID -> in-app group page
        domain.com/user/USER_ID -> in-app user page
        domain.com/invite/EVENT_ID,USER_ID -> in-app invite page
********/

const App = () => {
  let host = window.location.host;
  let protocol = window.location.protocol;
  let parts = host.split('.');
  let subdomain = '';
  // If we get more than 3 parts, then we have a subdomain
  // INFO: This could be 4, if you have a co.uk TLD or something like that.
  if (parts.length >= 3) {
    subdomain = parts[0];
    // Remove the subdomain from the parts list
    parts.splice(0, 1);
    // Set the location to the new url

    if (
      subdomain === 'login' ||
      subdomain === 'register' ||
      subdomain === 'waitlist' ||
      subdomain === 'dashboard' ||
      subdomain === 'app-store' ||
      subdomain === 'google-play' ||
      subdomain === 'admin' ||
      subdomain === 'download' ||
      subdomain === 'support'
    ) {
      window.location = protocol + '//' + parts.join('.') + '/' + subdomain;
    } else if (subdomain !== '') {
      window.location = protocol + '//' + parts.join('.') + '/404';
    }
  }

  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = onAuthStateChangedListener((user) => {
      const handleFirebasePull = async (user) => {
        const data = await getUserMetadata(user);

        if (data) {
          const userFields = {
            user: user,
            USER_ID: data.USER_ID,
            FIRST_NAME: data.FIRST_NAME,
            MIDDLE_NAME: data.MIDDLE_NAME,
            LAST_NAME: data.LAST_NAME,
            PHONE_NUMBER: data.PHONE_NUMBER,
            EMAIL_ADDRESS: data.EMAIL_ADDRESS,
            USER_ROLE: data.USER_ROLE,
            MAP_ACCESS: data.MAP_ACCESS,
            CURRENT_SUB_TIER: data.CURRENT_SUB_TIER,
            PROFILE_IMG_PATH: data.PROFILE_IMG_PATH,
            BIO: data.BIO,
            ATTENDED_EVENTS: data.ATTENDED_EVENTS,
            GOING_EVENTS: data.GOING_EVENTS,
            INTERESTED_EVENTS: data.INTERESTED_EVENTS,
            POSTED_EVENTS: data.POSTED_EVENTS,
            ALL_INVITED_EVENTS: data.ALL_INVITED_EVENTS,
            ALL_CHECKED_IN_EVENTS: data.ALL_CHECKED_IN_EVENTS,
            INVITED_EVENTS_RESPONDED: data.INVITED_EVENTS_RESPONDED,
            INVITED_EVENTS_PENDING_RESPONSE:
              data.INVITED_EVENTS_PENDING_RESPONSE,
            ADMIN_GROUPS: data.ADMIN_GROUPS,
            MEMBER_GROUPS: data.MEMBER_GROUPS,
            DATETIME_JOINED: data.DATETIME_JOINED,
            UNIVERSITY_AFFILIATED: data.UNIVERSITY_AFFILIATED,
            CURRENT_GRADE: data.CURRENT_GRADE,
            USER_DISABLED: data.USER_DISABLED,
            USER_DISABLED_BY: data.USER_DISABLED_BY,
            USER_DISABLED_REASON: data.USER_DISABLED_REASON,
            USER_DISABLED_DATETIME: data.USER_DISABLED_DATETIME,
            USER_LINKS: data.USER_LINKS,
            USER_SETTINGS: data.USER_SETTINGS,
            EXPO_DEVICE_PUSH_TOKENS: data.EXPO_DEVICE_PUSH_TOKENS,
          };
          dispatch(setCurrentUser(userFields));

          // console.log('User is signed in');
        } else {
          dispatch(resetCurrentUser());
          // console.log('User is not signed in');
        }
      };
      handleFirebasePull(user);
    });

    return () => unsubscribe();
  }, []);

  return (
    <BrowserRouter>
      {/* <NavBar /> */}

      <CookieConsent
        // debug={true} // this will show the cookie bar forever even if you accept it (for testing and styling purposes)
        location='bottom'
        buttonText='Accept & Close'
        cookieName='LegalWarningCookieConsent'
        style={{
          background:
            'linear-gradient(to right, rgb(249, 44, 44), rgb(255, 175, 0))',
        }}
        buttonStyle={{
          background: 'black',
          fontSize: '13px',
          fontWeight: 'bold',
          color: '#fff',
          borderRadius: '10px',
        }}
        expires={150}>
        This website uses cookies. By continuing to use this site, you accept
        our use of cookies, and you agree to our{' '}
        <a
          href={CONSTANTS.LEGAL.TERMS_OF_SERVICE_URL}
          target='_blank'>
          Terms of Service
        </a>{' '}
        and{' '}
        <a
          href={CONSTANTS.LEGAL.PRIVACY_POLICY_URL}
          target='_blank'>
          Privacy Policy
        </a>
        .{' '}
      </CookieConsent>

      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route
            path='/'
            element={<HomeRoute />}
          />
          <Route
            path='/dashboard'
            element={<DashboardRoute />}
          />
          <Route
            path='/admin'
            element={<AdminRoute />}
          />
          <Route
            path='/tos'
            element={<TOSRoute />}
          />
          <Route
            path='/privacy-policy'
            element={<PrivacyPolicyRoute />}
          />
          <Route
            path='/download/:REFERRAL_CODE?'
            element={<DownloadRoute />}
          />
          <Route
            path='/app-store'
            element={<AppStoreRoute />}
          />
          <Route
            path='/google-play'
            element={<GooglePlayRoute />}
          />
          <Route
            path='/login'
            element={<LoginRoute />}
          />
          <Route
            path='/register'
            element={<RegisterRoute />}
          />
          <Route
            path='/reset-password'
            element={<ResetPasswordRoute />}
          />
          <Route
            path='/waitlist'
            element={<WaitlistRoute />}
          />
          <Route
            path='/contact-us'
            element={<ContactUsRoute />}
          />
          <Route
            path='/support'
            element={<SupportRoute />}
          />

          {/* deep linking routes */}
          <Route
            path='/event/:EVENT_ID'
            element={
              isIOS ? (
                <AppStoreRoute />
              ) : isAndroid ? (
                <GooglePlayRoute />
              ) : (
                <DeepLinkQRCode type='event' />
              )
            }
          />
          <Route
            path='/user/:USER_ID'
            element={
              isIOS ? (
                <AppStoreRoute />
              ) : isAndroid ? (
                <GooglePlayRoute />
              ) : (
                <DeepLinkQRCode type='user' />
              )
            }
          />
          <Route
            path='/group/:GROUP_ID'
            element={
              isIOS ? (
                <AppStoreRoute />
              ) : isAndroid ? (
                <GooglePlayRoute />
              ) : (
                <DeepLinkQRCode type='group' />
              )
            }
          />
          <Route
            path='/invite/:EVENT_ID/:USER_ID'
            element={
              isIOS ? (
                <AppStoreRoute />
              ) : isAndroid ? (
                <GooglePlayRoute />
              ) : (
                <DeepLinkQRCode type='invite' />
              )
            }
          />

          {/* 404 not found catch all route */}
          <Route
            path='*'
            element={<NotFoundRoute />}
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
