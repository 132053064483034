var CONSTANTS = {
  METADATA: {
    LANDING_TITLE: 'Mixer',
    LEGAL_TITLE: 'Mixer Tech, Inc.',
    APP_NAME: 'Mixer',
  },

  DATA: {
    DEFAULT_NO_BIO_TEXT: 'A little spooky here! 👻',
    EVENT_FEEDBACK_GRAPH_RESULT_LIMIT: 10,
  },

  URLS: {
    BASE_URL: 'https://mixermeet.com',
    DOMAIN: 'mixermeet.com',
    PROTOCOL: 'https',
    REQUEST_A_GROUP_URL: 'https://forms.gle/q8SPvhe69K95tciP8',
    GROUP_MASS_MEMBER_ADD_INSTRUCTIONS_URL:
      'https://docs.google.com/document/d/e/2PACX-1vQ7r88vNwdQfks3HneotzW_lFgtE79zWt-h4SztwzbdgPBVOwK42CQxS1hm8ePYJ8L2JbO851NpMAzf/pub?embedded=true',
    GROUP_MASS_EVENT_ADD_INSTRUCTIONS_URL:
      'https://docs.google.com/document/d/e/2PACX-1vSxMrEAGKFTyISQ3qNUH9aheeKFHK1ubZw4Vg-CP90dbAd9INqXX3d2JEIBv9rKlHqUTmigtke7NM9q/pub?embedded=true',
    SUPPORT_TICKET_URL: 'https://mixertechinc.zohodesk.com/portal/en/newticket',
    APP_DOWNLOAD_URL: 'https://www.mixermeet.com/download',
    UNIVERSITY_LIST:
      'https://docs.google.com/spreadsheets/d/e/2PACX-1vSOXx9M09WlcB7KlATxD2IM3-7YWR7KlTb44AWGVcb2yRiRL5OhCT7jb8w42gMZb22ovogqEvSq5dme/pubhtml',
  },

  SOCIAL: {
    INSTAGRAM_URL: 'https://www.instagram.com/try.mixer/',
    FACEBOOK_URL: '',
    TWITTER_URL: 'https://www.twitter.com/trymixer',
    LINKEDIN_URL: '',
    TIK_TOK_URL: 'https://www.tiktok.com/@try.mixer',
    DISCORD_URL: '',
    EMAIL_ID: '',
  },

  CONTACT_INFO: {
    SUPPORT_EMAIL: 'support@mixermeet.com',
    PRIVACY_EMAIL: 'privacy@mixermeet.com',
    LEGAL_EMAIL: 'legal@mixermeet.com',
    GENERAL_EMAIL: 'hello@mixermeet.com',
  },

  AUTH: {
    MIN_PASSWORD_LENGTH: 8,
    RETURN_CODES: {
      SUCCESS: 0,
      ERROR: 1,
      DISABLED_ACCOUNT: 201,
      UNVERIFIED_EMAIL: 202,
      EMAIL_ALREADY_IN_USE: 203,
      TOO_MANY_REQUESTS: 204,
      USER_NOT_FOUND: 205,
      PHONE_NUMBER_ALREADY_IN_USE: 206,
      INVALID_PASSWORD: 207,
      INVALID_EMAIL: 208,
      INCORRECT_PASSWORD: 209,
    },
  },

  MIXER_APP: {
    APP_STORE_BUTTON_ENABLED: true,
    APP_STORE_LINK:
      'https://apps.apple.com/us/app/mixer-whats-happening/id6449442704',
    GOOGLE_PLAY_BUTTON_ENABLED: true,
    GOOGLE_PLAY_LINK:
      'https://play.google.com/store/apps/details?id=com.mixermeet.MixerMeet',
  },

  LEGAL: {
    TERMS_OF_SERVICE_URL: 'https://www.mixermeet.com/tos',
    PRIVACY_POLICY_URL: 'https://www.mixermeet.com/privacy-policy',
    TERMS_OF_SERVICE_PDF_URL: 'https://www.mixermeet.com/legal/tos.pdf',
    PRIVACY_POLICY_PDF_URL:
      'https://www.mixermeet.com/legal/privacy-policy.pdf',
  },

  WEBSITE_PATHS: {
    HOME: '/',
    LOGIN: '/login',
    REGISTER: '/register',
    RESET_PASSWORD: '/reset-password',
    CONTACT_US: '/contact-us',
    DASHBOARD: '/dashboard',
    WAITLIST: '/waitlist',
    PRIVACY_POLICY: '/privacy-policy',
    TOS: '/tos',
    ADMIN: '/admin',
    EVENT_DEEPLINK: '/event/',
    GROUP_DEEPLINK: '/group/',
    USER_DEEPLINK: '/user/',
    INVITE_DEEPLINK: '/invite/',
  },

  TEMP: {
    ACCEPTED_DOMAINS: ['stanford.edu', 'mixermeet.com'],
  },

  USER_ROLES: {
    COMPANY_ADMIN: 'company_admin',
    USER: 'user',
    UNIVERSITY_ADMIN: 'univ_approver',
  },

  GROUP_ROLES: {
    ADMIN: 'admin',
    MEMBER: 'member',
  },

  FETCH_DIRECTION: {
    NEXT: 'next',
    PREVIOUS: 'previous',
  },

  DB_RETURN_CODES: {
    SUCCESS: '000',
    FAILED: '001',
    USER: {
      USER_DOES_NOT_EXIST: '101',
    },
  },
};

export default CONSTANTS;
