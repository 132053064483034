import { AUTH_USER_ACTION_TYPES } from './auth-user.types';

const INITIAL_STATE = {
  currentUser: {
    user: null,
    USER_ID: '',
    FIRST_NAME: '',
    MIDDLE_NAME: '',
    LAST_NAME: '',
    PHONE_NUMBER: '',
    EMAIL_ADDRESS: '',
    USER_ROLE: '',
    MAP_ACCESS: [],
    CURRENT_SUB_TIER: '',
    PROFILE_IMG_PATH: '',
    BIO: '',
    ATTENDED_EVENTS: [],
    POSTED_EVENTS: [],
    ALL_INVITED_EVENTS: [],
    ALL_CHECKED_IN_EVENTS: [],
    INVITED_EVENTS_RESPONDED: [],
    INVITED_EVENTS_PENDING_RESPONSE: [],
    ADMIN_GROUPS: [],
    MEMBER_GROUPS: [],
    DATETIME_JOINED: 0,
    UNIVERSITY_AFFILIATED: true,
    CURRENT_GRADE: '',
    USER_DISABLED: false,
    USER_DISABLED_BY: null,
    USER_DISABLED_REASON: '',
    USER_DISABLED_DATETIME: 0,
    USER_LINKS: [],
    USER_SETTINGS: {
      SHOW_GROUPS_ON_PUBLIC_PROFILE: true,
      SHOW_EVENTS_ON_PUBLIC_PROFILE: true,
    },
    EXPO_DEVICE_PUSH_TOKENS: [],
  },
};

export const authUserReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case AUTH_USER_ACTION_TYPES.SET_CURRENT_USER:
      return { ...state, currentUser: payload };
    case AUTH_USER_ACTION_TYPES.RESET_CURRENT_USER:
      return { ...state, currentUser: INITIAL_STATE.currentUser };
    default:
      return state;
  }
};
